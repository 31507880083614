import React, { useCallback } from 'react'
import { DataType } from './TrafficSourcePerformanceReport'
import { isUndefined, sumBy } from 'lodash'
import Colors from './Colors'

export default function Summary({ column, data }) {

	const GetData = useCallback(() => {
		return data ? Object.values(data).filter((item) => item.itemType === 'campaign') : {};
	}, [data]);

	const Sum = useCallback((key) => {
		return sumBy(GetData(), key);
	}, [data]);

	const fn = {
		clicks: () => Sum('clicks'),
		impressions: () => Sum('impressions'),
		leads: () => Sum( 'leads'),
		ics: () => Sum('ics'),
		spend: () => Sum('spend'),
		revenue: () => Sum('revenue'),
		refund: () => Sum('refund'),
		refundAmount: () => Sum('refundAmount'),
		profit: () => Sum('profit') - Sum('refund'),
		roas: () => (Sum('revenue') / Sum('spend')),
		purchases: () => Sum('purchases'),
		ctr: () => (Sum('clicks') / Sum('impressions')) * 100,
		cpc: () => (Sum('spend') / Sum('clicks')),
		cpl: () => (Sum('spend') / Sum('leads')),
		vr25: () => (Sum('vr25Absolute') / Sum('impressions')) * 100,
		vr50: () => (Sum('vr50Absolute') / Sum('impressions')) * 100,
		vr75: () => (Sum('vr75Absolute') / Sum('impressions')) * 100,
		vr100: () => (Sum('vr100Absolute') / Sum('impressions')) * 100,
		hookRate: () => (Sum('hookRateAbsolute') / Sum('impressions')) * 100,
		refundPercent: () => (Sum('refund') / Sum('revenue')) * 100,
		refundAmountPercent: () => (Sum('refundAmount') / Sum('purchases')) * 100,
		cpm: () => (Sum('spend') / Sum('impressions')) * 1000,
		cpic: () => Sum('spend') / Sum('ics'),
		cpp: () => Sum('spend') / Sum('purchases'),
		cpvr3: () => Sum('cpvr3Summary') / Sum('cpvr3Impressions'),
		lCVR: () => (Sum('leads') / Sum('clicks')) * 100,
		icCVR: () => (Sum('ics') / Sum('clicks')) * 100,
		pCVR: () => (Sum('purchases') / Sum('clicks')) * 100,
	}

	function computeValue(column) {
		if (!Object.keys(fn).includes(column)) {
			return <>&mdash;</>
		}

		return fn[column]();
	}

	function getColumnValue(column) {
		if (column.key === 'item') {
			return null;
		}

		// All summary values are numbers.
		let val = computeValue(column.key);

		if (isUndefined(val) || val === Infinity || isNaN(val)) {
			return <>&mdash;</>
		}

		// If value is a float.
		if (!Number.isSafeInteger(val)) {
			val = val.toFixed(2);
		}

		if (column.key === 'profit') {
			column.color = val >= 0 ? Colors.green : Colors.red;
		}

		return column.dataType === DataType.Percentage ? `${val}%` : val;
	}

	function getColumnName(column) {
		if (column.key !== 'item') {
			return null;
		}

		return 'Summary';
	}

	if (column.key === ':control' || column.key === ':thumbnail') {
		return null;
	}

	if (column.key === 'item') {
		return (
			<div className="ka-cell-text">
				{ getColumnName(column) }
			</div>
		)
	}

	return getColumnValue(column);
}
